var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pa-0" },
    [
      _c(
        "v-img",
        {
          attrs: {
            height: _vm.$vuetify.breakpoint.height > 800 ? "100vh" : "auto",
            src: require("@/assets/trial.jpg"),
            eager: "",
            gradient: "to top, rgba(25, 25, 38, .85), rgba(23, 167, 193, .9)",
            "lazy-src":
              "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQABLAEsAAD/4QCMRXhpZgAATU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAAEsAAAAAQAAASwAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAB6gAwAEAAAAAQAAABQAAAAA/+0AOFBob3Rvc2hvcCAzLjAAOEJJTQQEAAAAAAAAOEJJTQQlAAAAAAAQ1B2M2Y8AsgTpgAmY7PhCfv/CABEIABQAHgMBIgACEQEDEQH/xAAfAAABBQEBAQEBAQAAAAAAAAADAgQBBQAGBwgJCgv/xADDEAABAwMCBAMEBgQHBgQIBnMBAgADEQQSIQUxEyIQBkFRMhRhcSMHgSCRQhWhUjOxJGIwFsFy0UOSNIII4VNAJWMXNfCTc6JQRLKD8SZUNmSUdMJg0oSjGHDiJ0U3ZbNVdaSVw4Xy00Z2gONHVma0CQoZGigpKjg5OkhJSldYWVpnaGlqd3h5eoaHiImKkJaXmJmaoKWmp6ipqrC1tre4ubrAxMXGx8jJytDU1dbX2Nna4OTl5ufo6erz9PX29/j5+v/EAB8BAAMBAQEBAQEBAQEAAAAAAAECAAMEBQYHCAkKC//EAMMRAAICAQMDAwIDBQIFAgQEhwEAAhEDEBIhBCAxQRMFMCIyURRABjMjYUIVcVI0gVAkkaFDsRYHYjVT8NElYMFE4XLxF4JjNnAmRVSSJ6LSCAkKGBkaKCkqNzg5OkZHSElKVVZXWFlaZGVmZ2hpanN0dXZ3eHl6gIOEhYaHiImKkJOUlZaXmJmaoKOkpaanqKmqsLKztLW2t7i5usDCw8TFxsfIycrQ09TV1tfY2drg4uPk5ebn6Onq8vP09fb3+Pn6/9sAQwACAwMDBAMEBQUEBgYGBgYICAcHCAgNCQoJCgkNEwwODAwODBMRFBEPERQRHhgVFRgeIx0cHSMqJSUqNTI1RUVc/9sAQwECAwMDBAMEBQUEBgYGBgYICAcHCAgNCQoJCgkNEwwODAwODBMRFBEPERQRHhgVFRgeIx0cHSMqJSUqNTI1RUVc/9oADAMBAAIRAxEAAAH5y6bz+x9xPoLtPk558r6vj3s3i3tX1/g87UWVB816f//aAAgBAQABBQJCElUVhE4NxTKtO32PNj+jcK513EyFIjvVJmFei36IrhRoZVU//9oACAEDEQE/AYjFAA+jjyDabHkCnN+LF/hYv//aAAgBAhEBPwHLlzZshiY3I8c+eQ54ETAB8SNvQD7epP8AuT/fLk/EX//aAAgBAQAGPwKh100elRXyrVotinVQ5ZXWvCnkHjIE+zXX1fxqzis0D5iVnmBftV1o0hSiR5dhTzY+Z7f/xAAzEAEAAwACAgICAgMBAQAAAgsBEQAhMUFRYXGBkaGxwfDREOHxIDBAUGBwgJCgsMDQ4P/aAAgBAQABPyGJYUP9qpFS4lB/E/mzUXrTeBQmExWtxByA5Q82EGQ5Dui0imegf8ywYAyFPuTRLFkLRY68XA6JXtWxKef6bhYk9l//2gAMAwEAAhEDEQAAEEjV2f/EADMRAQEBAAMAAQIFBQEBAAEBCQEAESExEEFRYSBx8JGBobHRweHxMEBQYHCAkKCwwNDg/9oACAEDEQE/EBAcU6e8f42+w0MOOZOW8L4/Ik4flf/aAAgBAhEBPxDOckY0GGC6gfnxchLS154z9vmDiGgx+TeUT+dv/9oACAEBAAE/ED6nKBeUlOnUWBSZ4FLyjXcmnYMuhhQgOCypUByGlAgu5QcBxygRMqTxZ/YgsjGnl5lcp8lDxRDHDFQWKdhzwvHLljixKYYL7oMai7ROVqX4m/gf3ThOhEmx7v8A/9k="
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "my-10", attrs: { "fill-height": "" } },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center flex-column align-center",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-column mb-16 white--text",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "display-1 font-weight-bold" },
                        [_vm._v("Request a trial now")]
                      ),
                      _c("span", { staticClass: " subtitle-2 mt-3" }, [
                        _vm._v(
                          "Please provide your details and we will reach out to you shortly"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto pa-5",
                      staticStyle: { opacity: "0.75" },
                      attrs: { flat: "", "max-width": "500" }
                    },
                    [
                      _c("div", { attrs: { id: "mc_embed_signup" } }, [
                        _c(
                          "form",
                          {
                            staticClass: "validate",
                            attrs: {
                              action:
                                "https://alphacruncher.us12.list-manage.com/subscribe/post?u=1457dbc3c5be915a28e53e73b&id=07b8187587",
                              method: "post",
                              id: "mc-embedded-subscribe-form",
                              name: "mc-embedded-subscribe-form",
                              target: "_self"
                            }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { id: "mc_embed_signup_scroll" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mc-field-group subtitle-1 primary--text"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "mce-EMAIL" } },
                                      [
                                        _vm._v(" E-Mail "),
                                        _c(
                                          "span",
                                          { staticClass: "asterisk" },
                                          [_vm._v("*")]
                                        )
                                      ]
                                    ),
                                    _c("input", {
                                      staticClass: "required email",
                                      attrs: {
                                        type: "email",
                                        value: "",
                                        name: "EMAIL",
                                        id: "mce-EMAIL",
                                        required: ""
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mc-field-group subtitle-1 primary--text"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "mce-NAME" } },
                                      [_vm._v("Name")]
                                    ),
                                    _c("input", {
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        name: "NAME",
                                        id: "mce-NAME"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mc-field-group subtitle-1 primary--text"
                                  },
                                  [
                                    _c("label", { attrs: { for: "mce-ORG" } }, [
                                      _vm._v("Organization/University")
                                    ]),
                                    _c("input", {
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        name: "ORG",
                                        id: "mce-ORG"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "clear",
                                    attrs: { id: "mce-responses" }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "response",
                                      staticStyle: { display: "none" },
                                      attrs: { id: "mce-error-response" }
                                    }),
                                    _c("div", {
                                      staticClass: "response",
                                      staticStyle: { display: "none" },
                                      attrs: { id: "mce-success-response" }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      left: "-5000px"
                                    },
                                    attrs: { "aria-hidden": "true" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        type: "text",
                                        name:
                                          "b_1457dbc3c5be915a28e53e73b_07b8187587",
                                        tabindex: "-1",
                                        value: ""
                                      }
                                    })
                                  ]
                                ),
                                _c("v-checkbox", {
                                  attrs: { label: "I am faculty member*" },
                                  model: {
                                    value: _vm.isFaculty,
                                    callback: function($$v) {
                                      _vm.isFaculty = $$v
                                    },
                                    expression: "isFaculty"
                                  }
                                }),
                                _c("div", { staticClass: "clear" }, [
                                  _c("input", {
                                    staticClass: "button",
                                    style: { opacity: _vm.isFaculty ? 1 : 0.5 },
                                    attrs: {
                                      type: "submit",
                                      value: "Submit",
                                      name: "subscribe",
                                      id: "mc-embedded-subscribe",
                                      disabled: !_vm.isFaculty
                                    }
                                  })
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "indicates-required" },
                                  [
                                    _c("span", { staticClass: "asterisk" }, [
                                      _vm._v("*")
                                    ]),
                                    _vm._v("Required fields")
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }