<template>
    <section class="pa-0">
        <v-img
            :height="$vuetify.breakpoint.height > 800 ? '100vh' : 'auto'"
            src="@/assets/trial.jpg"
            eager
            gradient="to top, rgba(25, 25, 38, .85), rgba(23, 167, 193, .9)"
            lazy-src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQABLAEsAAD/4QCMRXhpZgAATU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAAEsAAAAAQAAASwAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAB6gAwAEAAAAAQAAABQAAAAA/+0AOFBob3Rvc2hvcCAzLjAAOEJJTQQEAAAAAAAAOEJJTQQlAAAAAAAQ1B2M2Y8AsgTpgAmY7PhCfv/CABEIABQAHgMBIgACEQEDEQH/xAAfAAABBQEBAQEBAQAAAAAAAAADAgQBBQAGBwgJCgv/xADDEAABAwMCBAMEBgQHBgQIBnMBAgADEQQSIQUxEyIQBkFRMhRhcSMHgSCRQhWhUjOxJGIwFsFy0UOSNIII4VNAJWMXNfCTc6JQRLKD8SZUNmSUdMJg0oSjGHDiJ0U3ZbNVdaSVw4Xy00Z2gONHVma0CQoZGigpKjg5OkhJSldYWVpnaGlqd3h5eoaHiImKkJaXmJmaoKWmp6ipqrC1tre4ubrAxMXGx8jJytDU1dbX2Nna4OTl5ufo6erz9PX29/j5+v/EAB8BAAMBAQEBAQEBAQEAAAAAAAECAAMEBQYHCAkKC//EAMMRAAICAQMDAwIDBQIFAgQEhwEAAhEDEBIhBCAxQRMFMCIyURRABjMjYUIVcVI0gVAkkaFDsRYHYjVT8NElYMFE4XLxF4JjNnAmRVSSJ6LSCAkKGBkaKCkqNzg5OkZHSElKVVZXWFlaZGVmZ2hpanN0dXZ3eHl6gIOEhYaHiImKkJOUlZaXmJmaoKOkpaanqKmqsLKztLW2t7i5usDCw8TFxsfIycrQ09TV1tfY2drg4uPk5ebn6Onq8vP09fb3+Pn6/9sAQwACAwMDBAMEBQUEBgYGBgYICAcHCAgNCQoJCgkNEwwODAwODBMRFBEPERQRHhgVFRgeIx0cHSMqJSUqNTI1RUVc/9sAQwECAwMDBAMEBQUEBgYGBgYICAcHCAgNCQoJCgkNEwwODAwODBMRFBEPERQRHhgVFRgeIx0cHSMqJSUqNTI1RUVc/9oADAMBAAIRAxEAAAH5y6bz+x9xPoLtPk558r6vj3s3i3tX1/g87UWVB816f//aAAgBAQABBQJCElUVhE4NxTKtO32PNj+jcK513EyFIjvVJmFei36IrhRoZVU//9oACAEDEQE/AYjFAA+jjyDabHkCnN+LF/hYv//aAAgBAhEBPwHLlzZshiY3I8c+eQ54ETAB8SNvQD7epP8AuT/fLk/EX//aAAgBAQAGPwKh100elRXyrVotinVQ5ZXWvCnkHjIE+zXX1fxqzis0D5iVnmBftV1o0hSiR5dhTzY+Z7f/xAAzEAEAAwACAgICAgMBAQAAAgsBEQAhMUFRYXGBkaGxwfDREOHxIDBAUGBwgJCgsMDQ4P/aAAgBAQABPyGJYUP9qpFS4lB/E/mzUXrTeBQmExWtxByA5Q82EGQ5Dui0imegf8ywYAyFPuTRLFkLRY68XA6JXtWxKef6bhYk9l//2gAMAwEAAhEDEQAAEEjV2f/EADMRAQEBAAMAAQIFBQEBAAEBCQEAESExEEFRYSBx8JGBobHRweHxMEBQYHCAkKCwwNDg/9oACAEDEQE/EBAcU6e8f42+w0MOOZOW8L4/Ik4flf/aAAgBAhEBPxDOckY0GGC6gfnxchLS154z9vmDiGgx+TeUT+dv/9oACAEBAAE/ED6nKBeUlOnUWBSZ4FLyjXcmnYMuhhQgOCypUByGlAgu5QcBxygRMqTxZ/YgsjGnl5lcp8lDxRDHDFQWKdhzwvHLljixKYYL7oMai7ROVqX4m/gf3ThOhEmx7v8A/9k="
        >
            <v-container fill-height class="my-10">
                <div style="width: 100%" class="d-flex justify-center flex-column align-center">
                    <div style="width:100%" class="d-flex align-center justify-center flex-column mb-16 white--text">
                        <span class="display-1 font-weight-bold">Request a trial now</span>
                        <span class=" subtitle-2 mt-3">Please provide your details and we will reach out to you shortly</span>
                    </div>
                    <v-card flat class="mx-auto pa-5" max-width="500" style="opacity: 0.75">
                        <div id="mc_embed_signup">
                            <form
                                action="https://alphacruncher.us12.list-manage.com/subscribe/post?u=1457dbc3c5be915a28e53e73b&amp;id=07b8187587"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                class="validate"
                                target="_self"
                            >
                                <div id="mc_embed_signup_scroll">
                                    <div class="mc-field-group subtitle-1 primary--text">
                                        <label for="mce-EMAIL">
                                            E-Mail
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="email" value name="EMAIL" class="required email" id="mce-EMAIL" required />
                                    </div>
                                    <div class="mc-field-group subtitle-1 primary--text">
                                        <label for="mce-NAME">Name</label>
                                        <input type="text" value name="NAME" class id="mce-NAME" />
                                    </div>
                                    <div class="mc-field-group subtitle-1 primary--text">
                                        <label for="mce-ORG">Organization/University</label>
                                        <input type="text" value name="ORG" class id="mce-ORG" />
                                    </div>
                                    <div id="mce-responses" class="clear">
                                        <div class="response" id="mce-error-response" style="display:none"></div>
                                        <div class="response" id="mce-success-response" style="display:none"></div>
                                    </div>
                                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                        <input type="text" name="b_1457dbc3c5be915a28e53e73b_07b8187587" tabindex="-1" value />
                                    </div>
                                    <v-checkbox v-model="isFaculty" label="I am faculty member*"></v-checkbox>
                                    <div class="clear">
                                        <input
                                            type="submit"
                                            value="Submit"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            class="button"
                                            :disabled="!isFaculty"
                                            :style="{ opacity: isFaculty ? 1 : 0.5 }"
                                        />
                                    </div>
                                    <div class="indicates-required"><span class="asterisk">*</span>Required fields</div>
                                </div>
                            </form>
                        </div>
                    </v-card>
                </div>
            </v-container>
        </v-img>
    </section>
</template>

<script>
export default {
    data: function() {
        return {
            isFaculty: false
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
input[type='text'],
input[type='email'] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid black;
}
input[type='submit'] {
    background-color: #19a7c1;
    color: white;
    padding: 16px 32px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>
